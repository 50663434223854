import * as React from "react";
import { Pirsch } from "pirsch-sdk/web";
import { PirschClientContext } from "./PirschClientContext";
import { useConsent } from "../consent";

export const PirschClientProvider = ({ children }) => {
  const { hasConsent } = useConsent();

  const client = React.useRef(
    new Pirsch({
      identificationCode: "lesv8bsibsCLaKrdKNNRue8LP6I0PyBL",
      hostname: "toiletsofberlin.com",
    }),
  );

  React.useEffect(() => {
    const onRouteUpdatedListener = (event) => {
      if (hasConsent) {
        client.current.hit();
      }
    };

    document.addEventListener("tob-route-updated", onRouteUpdatedListener);

    return () => {
      document.removeEventListener("tob-route-updated", onRouteUpdatedListener);
    };
  }, [hasConsent]);

  if (!hasConsent) {
    return children;
  }

  return (
    <PirschClientContext.Provider value={client.current}>
      {children}
    </PirschClientContext.Provider>
  );
};
