import React from "react";
import { ConsentContext } from "./ConsentContext";

const VERSION = 1;
const LOCAL_STORAGE_KEY = `v${VERSION}-tob-consent`;

export const ConsentProvider = ({ children }) => {
  const [hasConsentStored, setHasConsentStored] = React.useState(false);
  const [hasConsent, setConsent] = React.useState(false);

  const updateConsent = React.useCallback((newHasConsent) => {
    setConsent(newHasConsent);
    window.localStorage.setItem(LOCAL_STORAGE_KEY, `${newHasConsent}`);
    setHasConsentStored(true);
  }, []);

  React.useEffect(() => {
    const storedHasConsent = window.localStorage.getItem(LOCAL_STORAGE_KEY);

    setHasConsentStored(storedHasConsent !== null);

    setConsent(storedHasConsent === "true");
  }, []);

  const consentValue = React.useMemo(() => {
    return {
      hasConsentStored,
      hasConsent,
      updateConsent,
    };
  }, [hasConsentStored, hasConsent, updateConsent]);

  return (
    <ConsentContext.Provider value={consentValue}>
      {children}
    </ConsentContext.Provider>
  );
};
