module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Toilets of Berlin","short_name":"Toilets of Berlin","start_url":"/","background_color":"#edab30","theme_color":"#edab30","display":"standalone","legacy":false,"icon":"src/images/icon.png","icons":[{"src":"icons/icon-96-96.png","sizes":"96x96","type":"image/png","purpose":"any"},{"src":"icons/icon-144-144.png","sizes":"144x144","type":"image/png","purpose":"any"},{"src":"icons/icon-192-192.png","sizes":"192x192","type":"image/png","purpose":"any"},{"src":"icons/icon-512-512.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"icons/icon-rounded-96-96.png","sizes":"96x96","type":"image/png","purpose":"maskable"},{"src":"icons/icon-rounded-144-144.png","sizes":"144x144","type":"image/png","purpose":"maskable"},{"src":"icons/icon-rounded-192-192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"icons/icon-rounded-512-512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"128768fb5e14ba30f15f28e7fce723ab"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
