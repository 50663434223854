import * as React from "react";

import "@fontsource/open-sans/latin-400.css";
import "@fontsource/open-sans/latin-600.css";
import "@fontsource/open-sans/latin-800.css";

import { PirschClientProvider } from "./src/pirsch";
import { ConsentProvider } from "./src/consent";

export const wrapRootElement = ({ element, props }) => {
  return (
    <ConsentProvider>
      <PirschClientProvider>{element}</PirschClientProvider>
    </ConsentProvider>
  );
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  window.setTimeout(() => {
    const routeUpdatedEvent = new CustomEvent("tob-route-updated", {
      detail: { location },
      bubbles: true,
    });

    document.dispatchEvent(routeUpdatedEvent);
  }, 0);
};
